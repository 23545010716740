/* @import url("https://fonts.googleapis.com/css2?family=Sansita&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Itim&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap"); */

body {
  /* font-family: 'Sansita', sans-serif; */
  font-family: "Itim", cursive;
  /* font-family: 'Rubik', sans-serif; */
  overflow-x: hidden;
  background: radial-gradient(circle at 10% 20%,
      rgba(216, 241, 230, 0.46) 0.1%,
      rgba(233, 226, 226, 0.28) 90.1%);
}

.activenote {
  border-radius: 10px;
  -webkit-box-shadow: 0px 7px 23px 12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 7px 23px 12px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 7px 23px 12px rgba(0, 0, 0, 0.75);
  padding: 20px;
  margin-bottom: 10px;
  overflow: hidden!important;
  width: 100%;
}
.inactivenote
{
  background: wheat;
  padding: 10px;
  padding-left: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
}
#addcursor
{
  cursor: pointer;
}

section {
  padding-top: 30px !important;
}

input,
textarea,
select {
  background: transparent !important;
}

span {
  color: #f53803;
}

/* Remove Google Branding */
#google_translate_element {
  color: transparent;
}

#google_translate_element a {
  display: none;
}

.translator {
  display: block;
  overflow: hidden !important;
}

div.goog-te-gadget {
  color: transparent !important;
}

.goog-te-combo {
  border: 2px solid #f53803 !important;
  border-radius: 15px;
  font-size: 20px !important;
  /* margin-top: -0.05px!important; */
  font-family: "Itim", cursive !important;
  color: black;
}

.itemslink {
  position: fixed;
  right: 0;
  padding: 20px;
}

#itemslinkbtn {
  background: linear-gradient(to left bottom, var(--g-color1), var(--g-color2));
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.2);
  color: white !important;
  width: 100%;
  height: auto;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
  text-align: center;
}

@media screen and (min-width:300px) and (max-width:1366px) {
  .itemslink {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 10px;
  }

  #itemslinkbtn {
    padding: 10px;
    width: 100%;
    max-width: 150px;
    margin-right: 10px;
  }
}

/* @media screen and (max-width: 962px) {
  .goog-te-combo {
    font-size: 20px !important;
  }
} */

/* Calculator */
.calciframe {
  width: 100%;
  height: auto;
  min-height: 60vh;
  border: none;
}

/* Navbar fixes */
.navbar-brand {
  margin-top: -10px;
}

@media screen and (max-width: 962px) {
  .navbar-brand {
    margin-top: 0px;
  }
}

.btngreen {
  background-color: green;
  color: white;
  border: 1px solid green;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  max-width: fit-content;
  height: auto;
}

.btnred {
  background-color: red;
  color: white;
  border: 1px solid red;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  max-width: fit-content;
  height: auto;
}

.btnyellow
{
  background-color: orange;
  color: white;
  border: 1px solid orange;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  max-width: fit-content;
  height: auto;
}

.h2bg {
  background: linear-gradient(-225deg, #FF057C 0%, #8D0B93 50%, #321575 100%);
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  max-width: fit-content;
  height: auto;
  text-align: center;
  cursor: default;
}

#btncolourfix button {
  background: linear-gradient(110.7deg, rgb(9, 154, 151) 6.3%, rgb(21, 205, 168) 90.6%);
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  max-width: fit-content;
  height: auto;
  cursor: pointer;
}

/* Main Navbar */
#main-bar {
  font-size: 22px;
}

/* Todos */
#TodoApp .li {
  list-style: none;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #f53803;
  border-radius: 10px;
  color: white;
  font-weight: bold;
}

.licomplete {
  list-style: none;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid green;
  border-radius: 10px;
  color: white;
  font-weight: bold;
}

.textcompleted {
  text-decoration: line-through;
  color: green;
}

.text {
  color: #f3482b;
}

#TodoApp input[type="checkbox"] {
  margin-right: 10px;
  float: left;
}

#align-right-btn {
  text-align: right;
}

.todo-input {
  background: transparent;
  border: 1px solid #f53803;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  max-width: fit-content;
  height: auto;
  min-height: 40px;
}

/* Dashboard Footer */
#DashboardFooter {
  cursor: default;
}

/* Take Notes */
#TakeNotesCss {
  background: linear-gradient(to left bottom, var(--g-color1), var(--g-color2));
  color: white;
  border: 1px solid wheat;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  height: auto;
}
.note img
{
  width: 100%;
  height: auto;
}

#TakeNotesCss input,
textarea {
  border-radius: 10px;
  border: 1px solid wheat;
  margin-bottom: 20px;
  width: 100%;
  height: auto;
  min-height: 40px;
}

#TakeNotesCss textarea {
  min-height: 100px;
}

/* Doubt Clearing */
.dcbgcss {
  background: linear-gradient(to left bottom, var(--g-color1), var(--g-color2));
  color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  height: auto;
}

#privacypolicy {
  text-align: justify;
}

a {
  text-decoration: none;
  color: gray;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

a:hover {
  text-decoration: none;
  color: gray;
}

/* Extras */
#LargeIcon {
  color: white;
  font-size: 4rem;
  text-align: center;
}

#extras-btn {
  background: linear-gradient(to left bottom, var(--g-color1), var(--g-color2));
  color: white;
  border: 1px solid wheat;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  max-width: fit-content;
  height: auto;
  min-height: 40px;
}

#extras-btn:hover {
  background: wheat !important;
  color: black;
  /* border: 1px solid #f53803; */
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 100%;
  max-width: fit-content;
  height: auto;
  min-height: 40px;
}

/* Sidebar Btn For Dashboard */
.sidebarbtn {
  margin: 10px;
  border: 1px solid #f53803;
  background-color: #f53803;
  color: white !important;
  width: 100%;
  max-width: fit-content;
  height: auto;
  min-height: 40px;
  padding: 10px;
  border-radius: 10px;
}

#extras-text {
  color: white;
  text-align: center;
}

#extras-headings {
  text-align: right;
}

/* Font Awesome Icons Styling */
#styleicon {
  /* font-size: 1.5rem; */
  color: #f53803;
}

#styleicon:hover {
  color: white !important;
  text-decoration: none;
}

/* Pricing Table */
.pricing-marquee {
  background-color: #f53803;
  color: white;
  width: 80%;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  font-size: 20px;
}

#demo span {
  color: white;
}

.demo {
  background-color: #f5f5f5;
}

.pricingTable {
  color: #3a3a3a;
  text-align: center;
  padding: 0 20px 35px;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 400px;
  z-index: 1;
  /* font-family: 'Sansita', sans-serif; */
}

.pricingTable:before {
  content: "";
  background: #fff;
  border-radius: 30px 0;
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.pricingTable .price-value {
  color: #fff;
  height: 150px;
  width: 150px;
  /* padding: 35px 0 0; */
  margin: 0 auto 50px;
  position: relative;
}

.pricingTable .price-value:before {
  content: "";
  background: linear-gradient(to top right, #974ab8, #3b3bbc);
  height: 100%;
  width: 100%;
  border-radius: 50% 50% 0 50%;
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.pricingTable .price-value .amount {
  font-size: 55px;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: -0.5px;
  display: block;
}

.pricingTable .price-value .duration {
  text-transform: uppercase;
  display: block;
}

.pricingTable .title {
  font-size: 35px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
}

.pricingTable .title:after {
  content: "";
  background: linear-gradient(to top right, #974ab8, #3b3bbc);
  height: 3px;
  width: 40%;
  margin: 10px auto 30px;
  clear: both;
  display: block;
}

.pricingTable .pricing-content {
  padding: 0;
  margin: 0 0 35px;
  list-style: none;
}

.pricingTable .pricing-content li {
  color: #7b7b7b;
  font-size: 17px;
  line-height: 22px;
  text-transform: uppercase;
  margin: 0 0 20px;
  position: relative;
  text-align: left;
  padding: 0 0 0 35px;
}

.pricingTable .pricing-content li:last-child {
  margin-bottom: 0;
}

.pricingTable .pricingTable-signup a {
  color: #fff;
  background: linear-gradient(to top right, #974ab8, #3b3bbc);
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  text-transform: uppercase;
  padding: 11px 30px;
  border-radius: 8px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

.pricingTable .pricingTable-signup a:hover {
  text-shadow: -2px 2px 1px #333;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.3);
}

.pricingTable.pink .price-value:before,
.pricingTable.pink .title:after,
.pricingTable.pink .pricingTable-signup a {
  background: linear-gradient(to top right, #bb2ca6, #c4334c);
}

.pricingTable.orange .price-value:before,
.pricingTable.orange .title:after,
.pricingTable.orange .pricingTable-signup a {
  background: linear-gradient(to top right, #f3482b, #e3b331);
}

@media only screen and (max-width: 990px) {
  .pricingTable {
    margin: 0 0 40px;
  }
}

/* Our Features */

.demo {
  background-color: #f5f5f5;
}

:root {
  --g-color1: #f87ba3;
  --g-color2: #ffcc68;
}

.featureTable {
  background: linear-gradient(to left bottom, var(--g-color1), var(--g-color2));
  padding: 40px 0 35px;
  border-radius: 50px;
  width: 100%;
  height: auto;
  max-width: 400px;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.2);
}

.featureTable .featureTable-header {
  color: #fff;
  text-align: left;
  padding: 0 0 0 35px;
  margin: 0 0 25px;
}

.featureTable .title {
  color: var(--g-color1);
  background-color: #fff;
  font-size: 35px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 10px 10px 10px 30px;
  margin: 0 0 25px;
  border-radius: 50px 0 0 50px;
}

.featureTable .feature-value {
  text-align: center;
  display: inline-block;
}

.featureTable .feature-value .currency {
  font-size: 23px;
  line-height: 23px;
  vertical-align: top;
  display: inline-block;
}

.featureTable .feature-value .amount {
  font-size: 75px;
  font-weight: 500;
  line-height: 58px;
  display: inline-block;
}

.featureTable .duration {
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 2px;
  display: block;
}

.featureTable .feature-content {
  text-align: left;
  padding: 0 15px 0 35px;
  margin: 0 0 15px;
  list-style: none;
  display: inline-block;
}

.featureTable .feature-content li {
  color: #fff;
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: 0.5px;
  padding: 0 15px 0 40px;
  margin: 0 0 10px;
  position: relative;
}

.featureTable .feature-content li:last-child {
  margin: 0;
}

.featureTable .feature-content li:before {
  font: var(--fa-font-solid);
  content: "\f00c";
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 17px;
  font-weight: 900;
  text-align: center;
  line-height: 31px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 0;
}

.featureTable .feature-content li.disable:before {
  content: "\f00d";
  color: rgba(255, 255, 255, 0.6);
}

.featureTable.blue {
  --g-color1: #54b2e5;
  --g-color2: #f67aa6;
}

@media only screen and (max-width: 990px) {
  .featureTable {
    margin: 0 0 40px;
  }
}

/* Vertically Align Centre */
.outer {
  display: table;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.btn {
  border: 1px solid #f53803;
}

.btn:hover {
  background-color: #f53803;
  border-color: #f53803;
  color: white !important;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  height: auto;
  width: 100%;
  /* max-width: 400px; */
}

/* Dashboard */
#dashboard-sidebar {
  text-align: center !important;
  font-size: 25px !important;
}

@media screen and (max-width: 962px) {
  #dashboard-sidebar {
    text-align: center !important;
    padding: 0px !important;
    font-size: 22px !important;
  }

  .navbar {
    margin-bottom: 20px;
  }
}

/* Vertically Alligned CSS Ends */

.navbar {
  background-color: white;
  /* border-bottom: 3px solid #f53803; */
  -webkit-box-shadow: 0px 7px 10px -2px rgba(245, 56, 3, 1);
  -moz-box-shadow: 0px 7px 10px -2px rgba(245, 56, 3, 1);
  box-shadow: 0px 7px 10px -2px rgba(245, 56, 3, 1);
}

.imgfix {
  width: 100%;
  height: auto;
}

.text-reset {
  text-decoration: none;
}

#animateimg {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

.menu_active {
  display: block;
  padding: 0.5rem 1rem;
  font-weight: bold;
  text-decoration: none;
  color: #f53803;
}

.menu_active:hover {
  color: #f53803;
}

.nav-link {
  color: black !important;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/* Basic Users */
#basiciframe {
  width: 100%;
  height: auto;
  min-height: 80vh;
  border: none;
}

#footer {
  text-align: center;
  bottom: 0;
  background-color: transparent !important;
}

#copyright-text {
  background-color: rgba(0, 0, 0, 0.025);
}

@media screen and (max-width: 962px) {
  .offcanvas {
    -webkit-box-shadow: -1px 0px 10px 9px rgba(245, 56, 3, 0.64);
    -moz-box-shadow: -1px 0px 10px 9px rgba(245, 56, 3, 0.64);
    box-shadow: -1px 0px 10px 9px rgba(245, 56, 3, 0.64);
  }
}

/* Login Page */
.login-cards {
  width: 100%;
  height: auto;
  max-width: 500px;
  border: none;
  /* border: 1px solid #F53803; */
  text-align: center;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0 0 15px -2px rgba(241, 135, 4, 0.2);
}

@media screen and (max-width: 962px) {
  .login-cards {
    max-width: 400px;
    margin-top: 20px;
  }
}

/* Teacher Classes */
#showLinks .col-sm {
  margin-bottom: 20px;
}